import {
  IFormRegister,
  ILogin,
  IMultiMedia,
  IPagination,
  IAppURL
} from '@constants/types';
import { API } from './endPoint';
import HttpClient from './service';
import cookie from 'js-cookie';
import { setToken } from './authClient';

export class VirtualEventOptionAPI extends HttpClient {
  constructor() {
    super(process.env.REACT_APP_DOMAIN_API_SERVER);
  }

  // CUSTOMIZE CODE
  private customizeUrlEndpoint = (
    params: ({ key: string; value: string } | { key: string; value: number })[],
  ) => {
    let url = '';
    params?.forEach((item) => {
      const { key, value } = item;
      url += `${key}=${encodeURIComponent(`${value}`)}&`;
    });
    return url;
  };
  //END CUSTOMIZE CODE

  // METHOD GET
  public getMultiMedia = async (params: IMultiMedia) => {
    const { SkipCount, MaxResultCount, TypeFilter } = params;
    let url = API.GET_MULTIMEDIA;
    const param = [
      { key: 'SkipCount', value: SkipCount * MaxResultCount },
      { key: 'MaxResultCount', value: MaxResultCount },
      { key: 'TypeFilter', value: TypeFilter },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };
  public getInforUser = async () => {
    const {
      data: { result },
    } = await this.instance.get(API.GET_INFO_USER);
    return result.user;
  };
  public getDetailGameQuiz = async (id: number) => {
    let url = API.GET_DETAIL_GAME_QUIZ;
    const param = [
      {
        key: 'id',
        value: id,
      },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };
  public getAllGames = async () => {
    const response = await this.instance.get(API.GET_ALL_QUIZ_GAME);
    return response;
  };
  public getGeneralQuestion = async (params: IPagination) => {
    const { SkipCount, MaxResultCount } = params;
    let url = API.GET_GENERAL_QUESTION;
    const param = [
      { key: 'SkipCount', value: SkipCount * MaxResultCount },
      { key: 'MaxResultCount', value: MaxResultCount },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };

  public getGameUserResult = async (params: IPagination) => {
    const { SkipCount, MaxResultCount } = params;
    let url = API.GET_GAME_USER_RESULT;
    const param = [
      { key: 'SkipCount', value: SkipCount * MaxResultCount },
      { key: 'MaxResultCount', value: MaxResultCount },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };

  public getGameUserTop = async (params: any) => {
    let url = API.GET_GAME_USER_TOP;
    const param = [
      { key: 'gameId', value: params.gameId },
      { key: 'selectTop', value: params.selectTop },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };
  public getRoomMeeting = async (id: number) => {
    let url = API.GET_ROOM_MEETING;
    const param = [{ key: 'id', value: id }];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };

  public getMeetingInfo = async (id: number) => {
    let url = API.GET_MEETING_INFO;
    const param = [{ key: 'id', value: id }];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance
      .get(url)
      .catch((error) => console.log(error));
    return response;
  };

  public getMessages = async (params: IPagination) => {
    let url = API.GET_MESSAGES;
    const param = [
      { key: 'SkipCount', value: params.SkipCount },
      { key: 'MaxResultCount', value: params.MaxResultCount },
      { key: 'Sorting', value: 'creationTime desc' },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = this.instance.get(url).catch((err) => console.log(err));
    return response;
  };

  public getProfilePicture = async () => {
    return await this.instance
      .get(API.GET_PROFILE_PICTURE)
      .catch((err) => console.log(err));
  };

  public getGroupChatForView = async (id: Number) => {
    return await this.instance.get(API.GET_GROUP_CHAT_FOR_VIEW + `id=${id}`);
  };

  public getAppUrlForView = async (params: IAppURL) => {
    let url = API.GET_APP_URL
    const param = [
      { key: 'TypeFilter', value: params.typeFilter },
    ];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    return await this.instance.get(url).catch((err) => console.log(err));
  };

  // END METHOD GET

  // METHOD POST
  public postLoginUser = async (form: ILogin) => {
    const {
      data: { result },
    } = await this.instance.post(API.AUTHENTICATE, form);
    setToken({ token: result.accessToken });
    const user = await this.getInforUser();
    return user;
  };
  public postRegisterUser = async (form: IFormRegister) => {
    const response = await this.instance.post(API.ACCOUNT_REGISTER, form);
    return response;
  };

  public postAnswerAndGetScore = async ({
    ans,
    gameId,
  }: {
    ans: number[];
    gameId: number;
  }) => {
    let url = API.POST_ANSWER_AND_GET_SCORE;
    const param = [{ key: 'gameId', value: gameId }];
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const response = await this.instance.post(url, ans);
    return response;
  };
  public postCodeResetPassword = async (data: {}) => {
    const response = await this.instance.post(
      API.POST_PASSWORD_RESET_CODE,
      data,
    );
    return response;
  };
  public postResetPassword = async (data: {}) => {
    const response = await this.instance.post(API.POST_RESET_PASSWORD, data);
    return response;
  };

  public postProfilePicture = async (data: any) => {
    let formData = new FormData();
    const [file] = data;
    formData.append('profilePictureFile', file.originFileObj);
    const param = [
      { key: 'fileName', value: file.name },
      { key: 'fileType', value: file.type },
      {
        key: 'fileToken',
        value: `${new Date().valueOf()}_${data[0].uid.replace(/\s/g, '_')}`,
      },
    ];
    let url = API.POST_PROFILE_PICTURE;
    url += this.customizeUrlEndpoint(param).replace(/[?&]$/, '');
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return await this.instance.post(url, formData, config);
  };

  // END METHOD POST

  // START METHOD PUT

  public putCurrentUserProfile = async (data: any) => {
    return await this.instance.put(API.PUT_CURRENT_USER_PROFILE, data);
  };

  public putProfilePicture = async (data: string) => {
    return await this.instance.put(API.PUT_PROFILE_PICTURE, data);
  };
  // END METHOD PUT

  public logoutUser = async () => {
    cookie.remove('token');
    cookie.remove('user');
  };
}
