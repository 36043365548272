import React from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { VirtualEventOptionAPI } from '@services/index';
import { Spin } from 'antd';
export interface AuthProviderConfig<User = unknown, Error = unknown> {
  key?: string;
  loadUser: (data: any) => Promise<User>;
  loginUser: (data: any) => Promise<User>;
  registerUser: (data: any) => Promise<User>;
  logoutUser: () => Promise<User>;
  loading?: boolean;
  LoaderComponent?: () => JSX.Element;
  ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element;
}

export interface AuthContextValue<
  IUser = unknown,
  Error = unknown,
  ILogin = unknown,
  IFormRegister = unknown,
> {
  user: IUser | undefined;
  login: UseMutateAsyncFunction<IUser, any, ILogin>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  register: UseMutateAsyncFunction<IUser, any, IFormRegister>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<IUser, Error>>;
  error: Error | null;
}
export interface AuthProviderProps {
  children: React.ReactNode;
}

export function initReactQueryAuth<
  User = unknown,
  Error = unknown,
  ILogin = unknown,
  IFormRegister = unknown,
>(config: AuthProviderConfig<User, Error>) {
  const AuthContext = React.createContext<AuthContextValue<
    User,
    Error,
    ILogin,
    IFormRegister
  > | null>(null);
  AuthContext.displayName = 'AuthContext';
  const {
    loadUser,
    loginUser,
    registerUser,
    logoutUser,
    key = 'auth-user',
    loading = true,
    LoaderComponent = () => (
      <div>
        <Spin
          size="large"
          spinning={true}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        />
      </div>
    ),
    ErrorComponent = (error: any) => (
      <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>
    ),
  } = config;
  function AuthProvider({ children }: AuthProviderProps): JSX.Element {
    const queryClient = useQueryClient();

    const {
      data: user,
      error,
      status,
      isLoading,
      isIdle,
      isSuccess,
      refetch,
    } = useQuery<User, Error>({
      queryKey: key,
      queryFn: loadUser,
    });

    const setUser = React.useCallback(
      (data: User) => {
        queryClient.setQueryData(key, data);
      },
      [queryClient],
    );

    const loginMutation = useMutation({
      mutationFn: loginUser,
      onSuccess: (user) => {
        setUser(user);
      },
    });

    const registerMutation = useMutation({
      mutationFn: registerUser,
      onSuccess: (user) => {
        setUser(user);
      },
    });

    const logoutMutation = useMutation({
      mutationFn: logoutUser,
      onSuccess: () => {
        queryClient.clear();
      },
    });

    const value = React.useMemo(
      () => ({
        user,
        error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggingIn: loginMutation.isLoading,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
        register: registerMutation.mutateAsync,
        isRegistering: registerMutation.isLoading,
      }),
      [user, error, refetch, loginMutation, logoutMutation, registerMutation],
    );

    if (isSuccess || !loading) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (isLoading || isIdle) {
      return <LoaderComponent />;
    }

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}
const serviceVirtualEventOptionAPI = new VirtualEventOptionAPI();
const authConfig = {
  loadUser: serviceVirtualEventOptionAPI.getInforUser,
  loginUser: serviceVirtualEventOptionAPI.postLoginUser,
  registerUser: serviceVirtualEventOptionAPI.postRegisterUser,
  logoutUser: serviceVirtualEventOptionAPI.logoutUser,
};

const { AuthProvider, AuthConsumer, useAuth } = initReactQueryAuth(authConfig);
export { AuthProvider, AuthConsumer, useAuth };
