import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonStyle = styled(Button)`
  width: 100%;
  background: #458ffc;
  border-radius: 10px;
  border: 1px solid black;
  padding: 15px 20px;
  line-height: unset;
  height: unset;
  font-size: 18px;
  color: #ffffff;
  border: none;
  transition: all 1s linear;
  //   &: hover {
  //     transition: all 1s linear;
  //     background-image: linear-gradient(
  //       to right,
  //       #25aae1,
  //       #4481eb,
  //       #04befe,
  //       #3f86ed
  //     );
  //   }
`;
