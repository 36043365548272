import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input)`
  padding: 15px 20px;
  border-radius: 10px;
`;
const StyledInputPassword = styled(Input.Password)`
  padding: 15px 20px;
  border-radius: 10px;
`;
const StyledInputGroup = styled(Input.Group)`
  padding: 15px 20px;
  border-radius: 10px;
`;

const StyledInputTextArea = styled(Input.TextArea)`
  padding: 15px 20px;
  border-radius: 10px;
`;
const StyledInputTextSearch = styled(Input.Search)`
  padding: 15px 20px;
  border-radius: 10px;
`;

export {
  StyledInput,
  StyledInputPassword,
  StyledInputGroup,
  StyledInputTextArea,
  StyledInputTextSearch,
};
