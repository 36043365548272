import { InputProps } from 'antd';
import React from 'react';
import {
  StyledInput,
  StyledInputGroup,
  StyledInputPassword,
  StyledInputTextArea,
  StyledInputTextSearch,
} from './styles';

type PropsInput = InputProps & {
  type?: string;
};


const Input = (props: PropsInput) => {
  let Component = <StyledInput {...props} />;
  switch (props.type) {
    case 'password':
      Component = <StyledInputPassword {...props} />;
      break;
    case 'group':
      Component = <StyledInputGroup/>;
      break;
    case 'textarea':
      Component = <StyledInputTextArea/>;
      break;
    case 'search':
      Component = <StyledInputTextSearch {...props} />;
      break;
  }
  return Component;
};

export default Input;
