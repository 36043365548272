import { VirtualEventOptionAPI } from '@services/index';
import { useMutation } from 'react-query';

const useUploadProfilePicture = () => {
  const serviceVirtualEventAPI = new VirtualEventOptionAPI();
  const uploadProfilePicture = useMutation((data: any) => {
    return serviceVirtualEventAPI.postProfilePicture(data);
  });
  return { uploadProfilePicture };
};
export default useUploadProfilePicture;
