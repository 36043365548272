import { VirtualEventOptionAPI } from '@services/index';
import { useQuery } from 'react-query';

const useGetProfilePicture = () => {
  const virtualEventAPI = new VirtualEventOptionAPI();
  const fetchProfilePicture = async () => {
    const profilePicture = await virtualEventAPI.getProfilePicture();
    return profilePicture?.data.result;
  };
  const { data, isLoading, isError } = useQuery('profilePicture', () =>
    fetchProfilePicture(),
  );
  const avatarBase = data?.profilePicture;
  return { avatarBase, isLoading, isError };
};

export default useGetProfilePicture;
