import cookie from 'js-cookie';
export const setToken = ({ token }: { token: string }) => {
  try {
    cookie.set('token', token, { expires: 356 });
  } catch (error) {
    // useHihistorystory.push("/login");
  }
};
export const setTokenUser = (user: any) => {
  cookie.set('user', JSON.stringify(user));
};
export const getTokenUser = () => {
  let user = cookie.get('user');
  return user != null ? JSON.parse(user) : null;
};
export const logout = (redirect = '/') => {
  cookie.remove('token');
  // to support logging out from all windows
  // window.localStorage.setItem("logout", Date.now());
  // Router.push(`/login?r=${encodeURIComponent(redirect)}`);
};
