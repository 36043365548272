import { Row, Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const WrapperRow = styled(Row)`
  z-index: 2;
  background: #458ffc;
  padding: 10px 0px;
  position: relative;
`;
const Img = styled.img`
  height: 60px;
  width: 50px;
  object-fit: contain;
`;
const NavLinkStyle = styled(NavLink)`
  color: #ffffff;
  padding: 5px 0px;
  text-align: center;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.94) 0%,
      rgba(255, 255, 255, 0.24) 52.08%,
      rgba(255, 255, 255, 0) 52.09%,
      rgba(255, 255, 255, 0.29) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 51.56%
    ),
    #007ff3;
  background-blend-mode: soft-light, normal, normal;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    inset 0px -2px 6px rgba(44, 0, 0, 0.05);
  border-radius: 32px;
  margin: 0px 15px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  &.active,
  &:hover {
    background-color: #ff6c3e;
    color: #ffffff;
    transition: 0.2s linear;
  }
`;
const NavLinkImg = styled(NavLink)`
  margin: 0px 20px;
`;
const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderRight = styled.div`
  position: absolute;
  right: 20px;
`;

const DropdowStyle = styled(Dropdown)`
  display: flex;
  justify-content: center;
  align-item: center;
`;

const SpaceAva = styled.div`
  text-align: center;
`;

const UserName = styled.div`
  font-size 10px;
  color: white;
  margin-top: 5px;
  white-space: nowrap;
`;
export {
  WrapperRow,
  Img,
  NavLinkImg,
  NavLinkStyle,
  HeaderCenter,
  HeaderRight,
  DropdowStyle,
  SpaceAva,
  UserName,
};
