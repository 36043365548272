import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    background: ${({ theme }) => theme.backgroundColor};
    font-family: 'Poppins', sans-serif;
    font-size: 1.15em;
    margin: 0;
  }
`;

export default GlobalStyles;
