import React from 'react';
import './App.less';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './routes';
import { ThemeStyledProvider } from './contexts/themeContext';
import { AvatarProvider } from '@contexts/avatarContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeStyledProvider>
        <AuthProvider>
          <AvatarProvider>
            <AppRoutes />
          </AvatarProvider>
        </AuthProvider>
      </ThemeStyledProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
