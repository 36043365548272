import theme from './themeConstant';

const lightTheme = {
  backgroundColor: theme.light.backgroundColor,
  borderRadius: '5px',
  colors: {
    a5a : "#5A5A5A",
    e7e : "#7E7E7E",
  },
  typo : {
    xlg : "36px",
    lg : "24px",
    md : "20px",
    sm : "16px",
    xs : "14px",
    typo : "10px"
  }
};

const darkTheme = {
  backgroundColor: theme.dark.backgroundColor,
  borderRadius: '5px',
  colors: {
  },
  typo : {
    xlg : "36px",
    lg : "24px",
    md : "20px",
    sm : "16px",
    xs : "14px",
    typo : "10px"
  }
};

export {lightTheme, darkTheme}
