import styled from 'styled-components';
import { Wrapper } from '@components/Wrapper/styles';
import { Form, Upload } from 'antd';

const WrapperContainer = styled(Wrapper)`
  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      71.46deg,
      #001493 6.95%,
      rgba(75, 75, 253, 0.84) 100%
    );
  }
`;

const WrapperContent = styled.div`
  position: absolute;
  z-index: 100;
  width: 30vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-item: center;
  flex-direction: column;
`;

const ImageStyle = styled.div`
  text-align: center;
  margin-bottom: 40px;
  .ant-image {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 100%;
    .ant-image-img {
      aspect-ratio: 1/1;
    }
  }
  .ant-image-mask {
    border-radius: 100%;
  }
`;

const UploadStyle = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    width: 0;
    height: 0;
    margin: 10px;
    background-color: none;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  .ant-btn-primary {
    border-radius: 5px;
  }
`;

const FormStyle = styled(Form)`
  .ant-form-item-label > label {
    color: #96999c;
    font-weight: 500;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;

export { WrapperContainer, WrapperContent, UploadStyle, FormStyle, ImageStyle };
