import { ButtonProps } from 'antd';
import { ButtonStyle } from './syles';
type PropsButton = {
  text: string;
  props: ButtonProps;
};
const ButtonBlue = ({ text, props }: PropsButton) => {
  return <ButtonStyle {...props}>{text}</ButtonStyle>;
};

export default ButtonBlue;
