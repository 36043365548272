import { Avatar, MenuProps, Menu, Space, Col } from 'antd';
import {
  LinkProps,
  useNavigate,
  useResolvedPath,
  useMatch,
} from 'react-router-dom';

import { useAuth } from '@contexts/AuthContext';
import {
  WrapperRow,
  Img,
  NavLinkImg,
  NavLinkStyle,
  HeaderCenter,
  HeaderRight,
  DropdowStyle,
  SpaceAva,
  UserName,
} from './styles';
import Vector from '@images/Vector.png';
import { useAvatarContext } from '@contexts/avatarContext';

const CustomLink = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLinkStyle to={to} {...props} className={match ? 'active' : ''}>
      {children}
    </NavLinkStyle>
  );
};
const Header = () => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      navigate('/edit-profile');
    } else if (key === '2') {
      logout().then(() => {
        navigate('/login');
      });
    }
  };
  return (
    <WrapperRow justify="center">
      <Col span={18}>
        <HeaderCenter>
          <CustomLink to="/info-event">Thông Tin</CustomLink>
          <CustomLink to="/collection">Bộ Sưu Tập</CustomLink>
          <CustomLink to="/video">Video</CustomLink>
          <NavLinkImg to="/home">
            <Img src={Vector} alt="img" />
          </NavLinkImg>
          <CustomLink to="/question">Game</CustomLink>
          <CustomLink to="/chat">Chat</CustomLink>
          <CustomLink to="/common-question">Câu Hỏi</CustomLink>
        </HeaderCenter>
      </Col>
      <HeaderRight>
        <DropdowStyle
          overlay={() => {
            return (
              <Menu
                onClick={handleMenuClick}
                items={[
                  {
                    label: 'Edit profile',
                    key: '1',
                  },
                  {
                    label: 'Logout',
                    key: '2',
                  },
                ]}
              />
            );
          }}
        >
          <a href="/" onClick={(e) => e.preventDefault()}>
            <Space>
              <SpaceAva>
                <Avatar size={40} src={useAvatarContext().avatar} />
                <UserName>{user.name}</UserName>
              </SpaceAva>
            </Space>
          </a>
        </DropdowStyle>
      </HeaderRight>
    </WrapperRow>
  );
};

export default Header;
