import styled from 'styled-components';

import background from '../../images/bg-main.jpg';

const Wrapper = styled.div`
  background: url(${background});
  width: 100%;
  height: 100vh;
  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export { Wrapper };
