import react, { useState, createContext, FC, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'styled/theme';
import GlobalStyles from 'styled/global';

interface Props {
  children: React.ReactNode;
}
const ThemeContext = createContext({ toggle: () => { } });
const useThemeContext = () => useContext(ThemeContext);

const ThemeStyledProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState('light');
  console.log('theme', theme);
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }
  return (
    <ThemeContext.Provider value={{ toggle: toggleTheme }}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <>
          <GlobalStyles />
          {children}
        </>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, useThemeContext, ThemeStyledProvider }