import { VirtualEventOptionAPI } from '@services/index';
import { useMutation } from 'react-query';

const usePutCurrentUserProfile = () => {
  const serviceVirtualEventOptionAPI = new VirtualEventOptionAPI();
  const putCurrentUserPrifile = useMutation((data: any) => {
    return serviceVirtualEventOptionAPI.putCurrentUserProfile(data);
  });
  return { putCurrentUserPrifile };
};
export default usePutCurrentUserProfile;
