import { useAuth } from '@contexts/AuthContext';
import EditProfile from '@pages/editProfile';
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

const Home = lazy(() => import('@pages/Home'));
const Login = lazy(() => import('@pages/Login'));
const ZoomJoinMeeting = lazy(() => import('@pages/zoom'));
const QuestionPage = lazy(() => import('@pages/question'));
const Register = lazy(() => import('@pages/register'));
const ChatMessage = lazy(() => import('@pages/chat'));
const Collection = lazy(() => import('@pages/collection'));
const CommonQuestion = lazy(() => import('@pages/commonQuestion'));
const Ranking = lazy(() => import('@pages/ranking'));
const Video = lazy(() => import('@pages/video'));
const Facebook = lazy(() => import('@pages/facebook'));
const WaittingEvent = lazy(() => import('@pages/waittingEvent'));
const InfoEvent = lazy(() => import('@pages/events'));
const ForgotPassword = lazy(() => import('@pages/forgotPassword'));
const Kuula = lazy(() => import('@pages/kuula'));

const PrivateRoute = () => {
  const location = useLocation();
  const { user } = useAuth();
  if (!user?.name) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return <Outlet />;
};

const AppRoutes = () => {
  const { user } = useAuth();
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route
            path="/login"
            element={!user?.name ? <Login /> : <Navigate to="/home" replace />}
          />
          <Route
            path="/register"
            element={
              !user?.name ? <Register /> : <Navigate to="/home" replace />
            }
          />
          <Route
            path="/account/reset-password"
            element={
              !user?.name ? <ForgotPassword /> : <Navigate to="/home" replace />
            }
          />
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/question" element={<QuestionPage />} />
            <Route path="/chat" element={<ChatMessage />} />
            <Route path="/collection" element={<Collection />} />
            <Route path="/common-question" element={<CommonQuestion />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/video" element={<Video />} />
            <Route path="/facebook" element={<Facebook />} />
            <Route path="/waitting-event" element={<WaittingEvent />} />
            <Route path="/info-event" element={<InfoEvent />} />
            <Route path="/zoom" element={<ZoomJoinMeeting />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/kuula" element={<Kuula />} />
          </Route>
          <Route
            path="*"
            element={!user?.name ? <Login /> : <Navigate to="/home" replace />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
