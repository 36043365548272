import useGetProfilePicture from '@hooks/useGetProfilePicture';
import { createContext, useContext, useEffect, useState } from 'react';

interface IAvatar {
  avatar: string;
  changeAvatar: (str: any) => void;
}
const avatarDefault =
  'https://kdstore.s3.ap-southeast-1.amazonaws.com/static/icons/Icon_Account-59.svg';
const AvatarContext = createContext({} as IAvatar);
const useAvatarContext = () => useContext(AvatarContext);

const AvatarProvider = ({ children }: any) => {
  const [avatar, setAvatar] = useState<string>('');
  const { avatarBase } = useGetProfilePicture();
  useEffect(() => {
    if (avatarBase) {
      setAvatar(`data:image/jpeg;base64,${avatarBase}`);
    } else {
      setAvatar(avatarDefault);
    }
  }, [avatarBase]);

  const changeAvatar = (str: string) => {
    setAvatar(str);
  };

  return (
    <AvatarContext.Provider value={{ avatar, changeAvatar }}>
      {children}
    </AvatarContext.Provider>
  );
};

export { AvatarProvider, useAvatarContext, AvatarContext };
