import { useContext, useEffect, useState } from 'react';
import { Button, Form, Image, message } from 'antd';
import type { UploadProps } from 'antd/es/upload/interface';
import Header from '@components/header';
import ButtonBlue from '@components/buttonBlue';
import Input from '@components/input';
import { useAuth } from '@contexts/AuthContext';
import {
  WrapperContainer,
  WrapperContent,
  UploadStyle,
  FormStyle,
  ImageStyle,
} from './styles';
import useUploadProfilePicture from '@hooks/useUploadProfilePicture';
import usePutCurrentUserProfile from '@hooks/usePutCurrentUserProfile';
import usePutProfilePicture from '@hooks/usePutProfilePicture';
import { AvatarContext } from '@contexts/avatarContext';

const EditProfile = () => {
  const avatarContext = useContext(AvatarContext);
  const { user } = useAuth();
  const [fileList, setFileList] = useState<any[]>([]);
  //   callAPI
  const { uploadProfilePicture } = useUploadProfilePicture();
  const { putCurrentUserPrifile } = usePutCurrentUserProfile();
  const { putProfilePicture } = usePutProfilePicture();

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onFinish = async (values: any) => {
    const { name, surname } = values;
    const { userName, emailAddress } = user;
    const params = {
      name,
      surname,
      userName,
      emailAddress,
    };
    try {
      await putCurrentUserPrifile.mutateAsync(params);
      message.success('Updated profile!');
    } catch (err) {
      message.error('update error profile!');
    }
  };
  const onFinishFailed = () => {
    message.error('update error profile!');
  };

  const OnChange: UploadProps['onChange'] = async ({
    fileList: newFileList,
  }) => {
    const res = await uploadProfilePicture.mutateAsync(newFileList);
    const { fileToken } = res.data.result;
    if (!fileToken) {
      message.error('Upload image failed!');
      return;
    }
    try {
      await putProfilePicture.mutateAsync({
        fileToken,
      });
      setFileList(newFileList);
      message.success('Upload image successfully!');
    } catch (err) {
      message.error('Upload image failed!');
    }
  };
  useEffect(() => {
    if (fileList.length > 0) {
      avatarContext.changeAvatar(
        URL.createObjectURL(fileList[0].originFileObj),
      );
    }
  }, [fileList]);

  return (
    <WrapperContainer>
      <Header />
      <WrapperContent>
        <ImageStyle>
          <Image width={'100px'} src={avatarContext.avatar} />
          <UploadStyle
            listType="picture-card"
            fileList={fileList}
            showUploadList={false}
            maxCount={1}
            onChange={OnChange}
            customRequest={dummyRequest}
            accept={'image/*'}
          >
            <Button htmlType="button" type="primary" className="upload-image">
              Select Image
            </Button>
          </UploadStyle>
        </ImageStyle>
        <FormStyle
          layout={'vertical'}
          name="edit-profile"
          initialValues={{ name: user.name, surname: user.surname }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            className="input-style"
            rules={[{ required: true, message: 'Please input your name!' }]}
            label="name"
            name="name"
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
          <Form.Item
            className="input-style"
            rules={[{ required: true, message: 'Please input your surname!' }]}
            label="surname"
            name="surname"
          >
            <Input placeholder="Enter your surname" />
          </Form.Item>
          <Form.Item>
            <ButtonBlue
              text={'Update Profile'}
              props={{
                htmlType: 'submit',
                className: 'edit-profile',
                type: 'primary',
              }}
            />
          </Form.Item>
        </FormStyle>
      </WrapperContent>
    </WrapperContainer>
  );
};

export default EditProfile;
