const themeConstant = {
  light: {
    primary:'#4677E7',
    backgroundColor : '#F0F2F5',
    backgroundColor_gray : '#F0F2F5'
  },
  dark : {
    primary:'#FFFFFF',
    backgroundColor : '#FFFFFF',
    backgroundColor_gray : '#F0F2F5'
  }
};
export default themeConstant;
