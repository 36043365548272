export const API = {
  AUTHENTICATE: 'api/TokenAuth/Authenticate',
  GET_DETAIL_GAME_QUIZ: '/api/services/app/Games/GetGameForView?',
  GET_ALL_QUIZ_GAME: 'api/services/app/Games/GetAll',
  POST_ANSWER_AND_GET_SCORE:
    'api/services/app/GameUserRecords/SendAnswerAndGetScore?',
  GET_INFO_USER: 'api/services/app/Session/GetCurrentLoginInformations',
  GET_MULTIMEDIA: 'api/services/app/Multimedias/GetAll?',
  GET_GENERAL_QUESTION: 'api/services/app/GeneralQuestions/GetAll?',
  GET_GAME_USER_RESULT: 'api/services/app/GameUserResults/GetAll',
  ACCOUNT_REGISTER: 'api/services/app/Account/Register',
  GET_GAME_USER_TOP: 'api/services/app/GameUserResults/GetTopUserByGame?',
  GET_ROOM_MEETING: '/api/services/app/MeetingRooms/GetMeetingRoomForView?',
  GET_MEETING_INFO: '/api/services/app/Informations/GetInformationForView?',
  POST_PASSWORD_RESET_CODE: '/api/services/app/Account/SendPasswordResetCode',
  POST_RESET_PASSWORD: '/api/services/app/Account/ResetPassword',
  GET_MESSAGES: 'api/services/app/GroupChatMessages/GetAll?',
  GET_PROFILE_PICTURE: '/api/services/app/Profile/GetProfilePicture',
  POST_PROFILE_PICTURE: '/api/services/app/Profile/UploadProfilePicture?',
  PUT_PROFILE_PICTURE: '/api/services/app/Profile/UpdateProfilePicture',
  PUT_CURRENT_USER_PROFILE:
    '/api/services/app/Profile/UpdateCurrentUserProfile',
  GET_GROUP_CHAT_FOR_VIEW: '/api/services/app/GroupChats/GetGroupChatForView?',
  GET_APP_URL: '/api/services/app/AppUrls/GetAll?'
};
