import { VirtualEventOptionAPI } from '@services/index';
import { useMutation } from 'react-query';

const usePutProfilePicture = () => {
  const serviceVirtualEventOptionAPI = new VirtualEventOptionAPI();
  const putProfilePicture = useMutation((data: any) => {
    return serviceVirtualEventOptionAPI.putProfilePicture(data);
  });
  return { putProfilePicture };
};
export default usePutProfilePicture;
